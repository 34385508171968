import { gql } from '@apollo/client';
import itemsFragmentQuery from './itemsFragmentQuery';
import genericFields from './genericFields';

const agencyItemsFragmentQuery = gql`
  fragment agencyItemFields on Item {
    ${genericFields}
    photos(limit: 1) {
      thumbnail
    }
  }
`;

export const agenciesRowQuery = gql`
  query AgenciesRowQuery {
    agencies(limitt: 8, sort: RANDOM) {
      items(limit: 1, sort: RANDOM) {
        ...agencyItemFields
      }
    }
  }
  ${agencyItemsFragmentQuery}
`;

export const agenciesQuery = gql`
  query AgenciesQuery {
    agencies(sort: RANDOM) {
      id
      name
      logoUrl
      description
      itemsCount
      path
    }
  }
`;

export function getAgencyDataQuery(slug) {
  const name = `GetAgencyDataQuery_${slug}`.replace(/[^_0-9A-Za-z]/g, '');
  return gql`
    query ${name}($slug: String!) {
      agency(slug: $slug) {
        id
        name
        description
        viewsCount
        contactAddress
        schedule
        phonesMasked
        latitude
        longitude
        logoUrl
        coverUrl
        itemsCount
        path
        itemCategories {
          id
          pluralName
        }
      }
      categories {
        id
        title
        children {
          id
          title
        }
      }
    }
  `;
}

export function getAgencyFilterDataQuery(filter, limit) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `GetAgencyFilterDataQuery_${filterString}_${limit}`;
  return gql`
    query ${name}(
      $first: Int
      $filter: ItemFilter
      $cursor: String
    ) {
      itemsConnection(
        first: $first
        after: $cursor
        filter: $filter
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...itemFields
          }
        }
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function getAgencyDataShortQuery(slug) {
  const name = `GetAgencyDataShortQuery_${slug}`.replace(/[^_0-9A-Za-z]/g, '');
  return gql`
    query ${name}($id: ID!) {
      agency(id: $id) {
        id
        itemCategories {
          id
          pluralName
        }
      }
      categories {
        id
        title
        children {
          id
          title
        }
      }
    }
  `;
}
